var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'form-inline d-flex': _vm.isInline, 'mb-3': _vm.hasBottomMargin}},[_c('label',{staticClass:"d-inline-block col-form-label font-size-lg font-size-lg mr-2",class:_vm.labelClasses,staticStyle:{"width":"auto !important"},attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.helperText)?_c('info-tooltip',{attrs:{"helper-text":_vm.helperText}}):_vm._e()],1),_c('div',{staticClass:"form-group mb-0",class:{
          'd-block': _vm.isInline,
          'd-inline': !_vm.isInline
        }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelProxy),expression:"modelProxy"}],staticClass:"form-control",class:{
                  'form-control-sm': _vm.size.toString() === 'sm',
                  'form-control-lg': _vm.size.toString() === 'lg',
                  'is-valid': Number(_vm.modelProxy) !== 0,
                  'mr-0': true,

              },attrs:{"disabled":_vm.disabled,"id":_vm.name,"name":_vm.name,"type":"number","placeholder":_vm.placeholder,"required":_vm.required,"min":_vm.min,"max":_vm.max,"step":_vm.step},domProps:{"value":(_vm.modelProxy)},on:{"input":function($event){if($event.target.composing)return;_vm.modelProxy=$event.target.value}}}),(_vm.hint)?_c('span',{staticClass:"form-text text-muted offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12"},[_vm._v(_vm._s(_vm.hint))]):_vm._e(),(_vm.error)?_c('p',{staticClass:"text-danger pt-1 px-3 block offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12"},[_vm._v(" "+_vm._s(_vm.$t(_vm.error)))]):_vm._e()]),_c('span',{staticClass:"d-inline-block px-1",domProps:{"innerHTML":_vm._s(_vm.getIconByKey(_vm.iconName, {
          class: 'w-20px h-20px object-cover d-inline-block opacity-75',
    }))}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.unitModelProxy),expression:"unitModelProxy"}],staticClass:"form-control",class:{
                'form-control-sm': _vm.size.toString() === 'sm',
                'form-control-lg': _vm.size.toString() === 'lg',
                'is-valid': Number(_vm.unitModelProxy) !== 0 && _vm.unitModelProxy !== 'null',
              },attrs:{"id":_vm.name,"required":_vm.required,"disabled":_vm.disabled || _vm.disabledUnit},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.unitModelProxy=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.unitOptions),function(value,index){return _c('option',{domProps:{"value":index}},[_vm._v(_vm._s(_vm.$t(value)))])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }