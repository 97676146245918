<template>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-start items-center">
      <span
        class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2"
        @click="$router.go(-1)"
      >
        <i class="fa fa-chevron-left"></i>
      </span>
      <h3>{{ $t("menu.bank_instructions") }}</h3>
    </div>
    <div class="col-md-12" v-if="!this.id">
      <dashboard-box>
        <template v-slot:preview>
          <!-- <div class="row">
            <div class="col-2" v-for="">

            </div>
          </div> -->
          <div class="row px-5 py-3">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex">
              <custom-multi-select
                :helperText="$t('general.please_select')"
                :required="false"
                class="font-weight-bolder"
                :input-max-width="'100%'"
                :input-width="'200px'"
                :model.sync="selectedFinancePayments"
                :name="'aprovable_invoices'"
                :options="financePaymentOptions"
                :title="null"
                :showInvalid="false"
                :is-valid="false"
                size="sm"
              ></custom-multi-select>
              <span
                @click="refreshItems()"
                v-html="
                  getIconByKey('icons.general.reset', {
                    class: 'w-25px h-25px d-inline-block object-fill cursor-pointer',
                  })
                "
              >
              </span>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <button-with-icon
                :icon-name="null"
                :text="$t('general.add').toUpperCase()"
                class="mx-1 font-weight-boldest"
                size="md"
                @onClick="addNewFinancePayment()"
              ></button-with-icon>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <button-with-icon
                :icon-name="null"
                :text="$t('general.add_all').toUpperCase()"
                class="mx-1 font-weight-boldest"
                size="md"
                @onClick="addAllFinancePayment()"
              ></button-with-icon>
            </div>
          </div>
          <div class="col-12">
            <div class="d-flex flex-row justify-content-center align-items-center">
              <div class="flex-fill" v-for="unit in currencyUnits" v-if="unit.is_active">
                <span>{{ unit.code }}</span>
                <text-input
                  class="font-weight-bolder"
                  :model.sync="unit.buying"
                  input-max-width="auto"
                  input-width="auto"
                  :is-inline="true"
                  :required="false"
                ></text-input>
              </div>
            </div>
          </div>
          <div class="relative">
            <div class="w-100 px-5 py-3">
              <div class="w-100 row border-15px navy-blue-border-1px mx-0 mt-5 mb-1">
                <table class="table border-15px mb-0">
                  <thead class="w-100">
                    <tr>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("general.supplier_company") }}
                        </div>
                      </th>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("invoice.amount_total") }}
                        </div>
                      </th>
                      <th
                        class="navy-blue-border-bottom-1px justify-content-center items-center text-lg text-center"
                      >
                        #
                      </th>
                    </tr>
                  </thead>
                  <tbody class="navy-blue-bottom-1px">
                    <template v-for="(item, index) in formatedFinancePaymentItems">
                      <tr style="width: 40%">
                        <td class="navy-blue-border-right-1px">
                          {{ item.supplier_company }}
                          <i
                            class="fa fa-eye fa-1x pr-1"
                            role="button"
                            @click="showFinancePaymentItem(item.id)"
                          >
                          </i>
                        </td>
                        <td
                          class="navy-blue-border-right-1px d-flex justify-content-center align-items-center"
                        >
                          <div class="row d-flex justify-content-center">
                            <div class="col-12 text-center">
                              <table>
                                <thead class="table table-sm">
                                  <th>Ödenecek Miktar</th>
                                  <th>Ödenmek İstenen Miktar (TL)</th>
                                </thead>
                                <tbody>
                                  <template v-for="totalCurrencyUnit in item.total.items">
                                    <tr>
                                      <td class="navy-blue-border-right-1px">
                                        {{ totalCurrencyUnit.total | invoiceFormat }}
                                        <b>{{ totalCurrencyUnit.currency_unit }}</b>
                                      </td>
                                      <td>
                                        <!-- <amount-inputs
                                        class="font-weight-bolder"
                                        :model.sync="totalCurrencyUnit.amount"
                                        input-max-width="auto"
                                        input-width="auto"
                                        :is-inline="true"
                                        :required="false"
                                      >
                                      </amount-inputs> -->
                                        <text-input
                                          class="font-weight-bolder"
                                          :model.sync="totalCurrencyUnit.amount"
                                          input-max-width="auto"
                                          input-width="auto"
                                          :is-inline="true"
                                          :required="false"
                                        ></text-input>
                                      </td>
                                    </tr>
                                  </template>

                                  <tr>
                                    <th class="navy-blue-border-right-1px">
                                      Genel Toplam:
                                    </th>
                                    <td style="text-align: left; padding-left: 25px">
                                      {{ totalTl(item) | invoiceFormat }}
                                      <!-- {{ item.amount_to_be_paid_text | invoiceFormat }} -->
                                      <b>TL</b>
                                    </td>
                                  </tr>
                                  <!-- <tr>
                                    <th>Ödenemek İstenen Genel Toplam</th>
                                    <td>
                                      <amount-inputs
                                        class="font-weight-bolder"
                                        :model.sync="item.amount_to_be_paid"
                                        input-max-width="auto"
                                        input-width="auto"
                                        :is-inline="true"
                                        :required="false"
                                      >
                                      </amount-inputs>
                                      <text-input
                                        class="font-weight-bolder"
                                        disabled
                                        :model.sync="item.amount_to_be_paid"
                                        input-max-width="auto"
                                        input-width="auto"
                                        :is-inline="true"
                                        :required="false"
                                      ></text-input>
                                    </td>
                                  </tr> -->
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span
                            class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                            @click="deleteFinancePayment(item.id)"
                          >
                            <span
                              v-html="
                                getIconByKey('icons.waybill.delete_2', {
                                  class:
                                    'w-20px h-20px object-cover d-inline-block opacity-75',
                                })
                              "
                            >
                            </span>
                            {{ $t("general.delete").toUpperCase() }}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            class="row col-12 justify-content-center mt-10 pt-10"
            style="display: inline-flex; margin-top: 5% !important"
          >
            <div
              class="col-2 text-center"
              v-if="formatedFinancePaymentItems.length >= 1 && disabledSubmit"
            >
              <span
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                @click="createBankInstruction()"
              >
                <span
                  v-html="
                    getIconByKey('icons.waybill.save', {
                      class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                    })
                  "
                >
                </span>
                {{ $t("general.save").toUpperCase() }}
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
    <div class="col-md-12" v-else>
      <dashboard-box v-if="financePayments">
        <template v-slot:preview>
          <div class="relative">
            <div class="w-100 px-5 py-3">
              <div class="w-100 row border-15px navy-blue-border-1px mx-0 mt-5 mb-1">
                <table class="table border-15px mb-0">
                  <thead class="w-100">
                    <tr>
                      <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("general.supplier_company") }}
                        </div>
                      </th>
                      <th class="navy-blue-border-bottom-1px">
                        <div class="d-flex justify-content-center items-center text-lg">
                          {{ $t("invoice.amount_total") }}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="navy-blue-bottom-1px">
                    <template v-for="(item, index) in financePaymentsFormated">
                      <tr>
                        <td class="navy-blue-border-right-1px">
                          {{ item.supplier_company.name }}
                          <i
                            class="fa fa-eye fa-1x pr-1"
                            role="button"
                            @click="showFinancePaymentItem(item.id)"
                          >
                          </i>
                        </td>
                        <td>
                          <table class="table table-sm">
                            <thead>
                              <th>Ödenmek İstenen Miktar</th>
                              <th>Ödenen Miktar (TL)</th>
                            </thead>
                            <tbody>
                              <tr v-for="totalCurrencyUnit in item.total.items">
                                <td>
                                  {{ totalCurrencyUnit.total | invoiceFormat }}
                                  <b>{{ totalCurrencyUnit.currency_unit }}</b>
                                </td>
                                <td v-if="totalCurrencyUnit.currency_total">
                                  {{
                                    totalCurrencyUnit.currency_total.total | invoiceFormat
                                  }}
                                  <b>{{
                                    totalCurrencyUnit.currency_total.currency_unit
                                  }}</b>
                                  /
                                  {{
                                    totalCurrencyUnit.currency_total.amount
                                      | invoiceFormat
                                  }}
                                  <b>TL</b>
                                </td>
                                <td v-else>-</td>
                              </tr>
                              <tr>
                                <td><b>Toplam:</b></td>
                                <td>{{ item.total.total | invoiceFormat }} <b>TL</b></td>
                              </tr>
                              <tr>
                                <td><b>Ödenen Toplam:</b></td>
                                <td>
                                  {{ item.amount_to_be_paid | invoiceFormat }} <b>TL</b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            class="row col-12 justify-content-center mt-10 pt-10"
            style="display: inline-flex; margin-top: 5% !important"
          >
            <div class="d-flex justify-content-center text-center">
              <span
                @click="downloadExcelLink()"
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue"
              >
                <span
                  v-html="
                    getIconByKey('icons.waybill.excel', {
                      class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                    })
                  "
                >
                </span>
                {{ $t("bank_instruction.download_excel").toUpperCase() }}
              </span>

              <span
                @click="deleteBankInstructions()"
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue"
              >
                <i class="fas fa-trash-alt mr-1" style="color: #1a283f"></i>
                {{ $t("general.delete").toUpperCase() }}
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/assets/components/inputs/TextInput";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DataTable from "@/assets/components/dataTable/DataTable";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import AmountInputs from "@/assets/components/inputs/AmountInputs";
import { invoiceFormat } from "@/core/filter/number.filter.js";
import axios from "axios";
import {
  GET_ITEMS,
  LOADING,
  RESET_VALUES,
  CREATE_ITEM,
  SET_LOADING,
  DELETE_ITEM_BY_ID,
} from "@/core/services/store/bank-instruction/bankInstruction.module";

import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";

export default {
  name: "InvoiceCreateEdit",
  components: {
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    TextInput,
    DashboardBox,
    DataTable,
    TextareaInput,
    NumberInputWithUnit,
    AmountInputs,
  },
  data() {
    return {
      invoiceCurrencyUnitList: [],
      currencyUnits: [],
      currencyUnitList: [],
      financePayments: null,
      financePaymentRows: null,
      defaultCurrency: "TL",
      id: this.$route.params.id,
      selectedFinancePayments: [],
      financePaymentItems: [],
      disabledSubmit: true,
      tmpItems: [],
      tmpItemIfId: [],
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    moment: () => moment,

    checkFinanceManager() {
      return this.isUserGranted("Staff", ["financeManager"], false);
    },
    financePaymentOptions() {
      let data = this.financePayments;
      return this.convertArrayToObjectByKey(data, "id", "supplier_company.name");
    },
    //eğer id yoksa
    formatedFinancePaymentItems() {
      let data = this.financePaymentItems;

      data.forEach((item) => {
        let invoicePaymentRequestItems = item.invoices;
        let supplier_company_name = item.supplier_company.name;
        let forCurrencyTypeList = [];
        let totalTl = 0;
        let totalAmountPaid = 0;
        invoicePaymentRequestItems.forEach((invoicePaymentRequestItem) => {
          let invoice = invoicePaymentRequestItem.invoice;
          // Döviz Biriminin otomatik hesaplanması için yazılmış kod
          let findItem = forCurrencyTypeList.findIndex(
            (financePayments) =>
              financePayments.currency_unit_id === invoice.currency_unit.id
          );

          let findCurrUnit = _.find(this.currencyUnits, {
            code: invoice.currency_unit.code,
          });

          Object.keys(this.currencyUnits).forEach((key) => {
            if (this.currencyUnits[key]["code"] == invoice.currency_unit.code) {
              this.currencyUnits[key]["is_active"] = true;
            }
          });
          if (findItem !== -1) {
            // döviz kurunun birimi ve satış fiyatı kayıt altına alınıyor
            this.currencyUnitList.push({
              code: findCurrUnit.code,
              buying: findCurrUnit.buying,
            });
            // eğer bu tevkifatlı fatura ise
            if (invoice.withholding_amount > 0) {
              let total =
                invoicePaymentRequestItem.total_amount > 0
                  ? Number(invoicePaymentRequestItem.total_amount)
                  : Number(invoice.amount_to_be_paid);
              let amount = total * findCurrUnit.buying;
              forCurrencyTypeList[findItem].total += Number(total);
              forCurrencyTypeList[findItem].amount += Number(amount);
            } else {
              let total =
                invoicePaymentRequestItem.total_amount != null
                  ? Number(invoicePaymentRequestItem.total_amount)
                  : Number(invoice.total_amount_including_taxes);
              let amount = total * findCurrUnit.buying;
              forCurrencyTypeList[findItem].total += Number(total);
              forCurrencyTypeList[findItem].amount += Number(amount);
            }
          } else {
            // tevkifatlı fatura olup olmadığı kontrol ediliyor
            let check_withholding_amount =
              invoice.withholding_amount > 0
                ? +invoice.amount_to_be_paid
                : +invoice.total_amount_including_taxes;
            // tevkifatlı fatura durumuna göre işlemler yapılıyor
            let total =
              invoicePaymentRequestItem.total_amount != null
                ? Number(invoicePaymentRequestItem.total_amount)
                : Number(check_withholding_amount);
            this.invoiceCurrencyUnitList.push(invoice.currency_unit.code);

            let amount = total * findCurrUnit.buying;
            forCurrencyTypeList.push({
              currency_unit_id: +invoice.currency_unit.id,
              currency_unit: invoice.currency_unit.code,
              amount: amount,
              total: total,
            });
          }

          // let check_withholding_amount =
          //   invoice.withholding_amount > 0
          //     ? Number(invoice.amount_to_be_paid)
          //     : Number(invoice.total_amount_including_taxes * findCurrUnit.buying);

          // totalAmountPaid +=
          //   invoicePaymentRequestItem.total_amount_converted != null
          //     ? Number(invoicePaymentRequestItem.total_amount * findCurrUnit.buying)
          //     : Number(check_withholding_amount);

          // if (!(invoice.currency_unit_id == 160 || invoice.currency_unit_id == 53)) {
          //   let check_withholding_amount =
          //     invoice.withholding_amount > 0
          //       ? Number(invoice.amount_to_be_paid)
          //       : Number(invoice.total_amount_including_taxes * findCurrUnit.buying);
          //   totalTl += check_withholding_amount;
          //   totalAmountPaid = totalTl;
          // }
        });
        forCurrencyTypeList.forEach((currItem) => {
          totalAmountPaid += currItem.amount;
        });
        let tmpItemsIndex = this.tmpItems.findIndex((tmp) => tmp.id == item.id);
        if (tmpItemsIndex == -1) {
          this.tmpItems.push({
            supplier_company: supplier_company_name,
            total: {
              items: forCurrencyTypeList,
            },
            amount_to_be_paid_text:
              item.amount_to_be_paid == 0 ? totalAmountPaid : item.amount_to_be_paid,
            amount_to_be_paid:
              item.amount_to_be_paid == 0 ? totalAmountPaid : item.amount_to_be_paid,
            id: item.id,
          });
        } else {
          // yeni değerlerin döviz kuru ile tekrardan return olması için yazıldı
          this.tmpItems[tmpItemsIndex]["total"]["items"] = forCurrencyTypeList;
          this.tmpItems[tmpItemsIndex]["amount_to_be_paid_text"] = totalAmountPaid;
          this.tmpItems[tmpItemsIndex]["amount_to_be_paid"] = totalAmountPaid;
        }
      });
      return this.tmpItems;
    },
    //eğer Id varsa
    financePaymentsFormated() {
      let item = this.financePayments;
      let items = item.bank_instruction_items;
      let tmpItem = [];

      items.forEach((paymentItem) => {
        let invoices = paymentItem.invoice_payment.invoices;
        let forCurrencyTypeList = [];
        let totalTl = 0;
        invoices.forEach((invoiceItem) => {
          let findItem = forCurrencyTypeList.findIndex(
            (financePayments) =>
              financePayments.currency_unit_id === invoiceItem.invoice.currency_unit.id
          );
          if (findItem !== -1) {
            if (invoiceItem.invoice.withholding_amount > 0) {
              forCurrencyTypeList[findItem].total =
                Number(forCurrencyTypeList[findItem].total) +
                Number(invoiceItem.invoice.amount_to_be_paid);
            } else {
              forCurrencyTypeList[findItem].total =
                Number(forCurrencyTypeList[findItem].total) +
                Number(invoiceItem.invoice.total_amount_including_taxes);
            }
          } else {
            let findCurrItem = _.find(paymentItem.invoice_payment.currency_total, {
              currency_unit_id: invoiceItem.invoice.currency_unit.id,
            });
            forCurrencyTypeList.push({
              currency_unit_id: +invoiceItem.invoice.currency_unit.id,
              currency_unit: invoiceItem.invoice.currency_unit.code,
              currency_total: findCurrItem,
              total:
                invoiceItem.invoice.withholding_amount > 0
                  ? invoiceItem.invoice.amount_to_be_paid
                  : +invoiceItem.invoice.total_amount_including_taxes,
            });
          }
          if (
            !(invoiceItem.currency_unit_id == 160 || invoiceItem.currency_unit_id == 53)
          ) {
            totalTl +=
              invoiceItem.invoice.withholding_amount > 0
                ? +invoiceItem.invoice.amount_to_be_paid
                : +invoiceItem.invoice.total_amount_including_taxes_converted;
          }
        });
        tmpItem.push({
          supplier_company: paymentItem.invoice_payment.supplier_company,
          total: {
            items: forCurrencyTypeList,
            total: totalTl,
          },
          amount_to_be_paid: paymentItem.invoice_payment.amount_to_be_paid,
          id: paymentItem.invoice_payment_request_id,
        });
      });
      return tmpItem;
    },
  },
  methods: {
    ...mapMutations({}),
    deleteBankInstructions() {
      let self = this;
      if (self.isUserGranted("BankInstruction", ["delete"], false)) {
        self.sweetAlertConfirm(
          this.$t("general.are_you_sure"),
          function (self) {
            self.$store.commit(SET_LOADING, true);
            let payload = {
              url: "api/bank-instruction/" + self.id,
            };
            self.$store.dispatch(DELETE_ITEM_BY_ID, payload).then((response) => {
              self.$store.commit(SET_LOADING, false);
              self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
              self.$router.push({ name: "bank_instructions.index" });
            });
          },
          () => {}
        );
      }else{
        self.sweetAlertError(self.$t("general.you_have_no_authorized_to_do_this_action"));
      }
    },
    totalTl(item) {
      let tmpTotalTl = 0;
      let currItem = item.total.items;
      currItem.forEach((cur) => {
        tmpTotalTl += Number(cur.amount);
      });
      return tmpTotalTl;
    },
    addNewFinancePayment() {
      if (this.selectedFinancePayments == null) {
        this.sweetAlertError(this.$t("bank_instruction.please_selected_finance_payment"));
        return false;
      }
      this.selectedFinancePayments.forEach((item) => {
        let id = item;
        let index = this.financePayments.findIndex((payment) => payment.id == id);
        this.financePaymentItems.push(this.financePayments[index]);
        this.financePayments.splice(index, 1);
      });
      this.selectedFinancePayments = [];
    },
    addAllFinancePayment() {
      if (this.selectedFinancePayments == null) {
        this.sweetAlertError(this.$t("bank_instruction.please_selected_finance_payment"));
        return false;
      }
      let financePayments = _.cloneDeep(this.financePayments);
      financePayments.forEach((item) => {
        let id = item.id;
        let index = this.financePayments.findIndex((payment) => payment.id == id);
        this.financePaymentItems.push(this.financePayments[index]);
        this.financePayments.splice(index, 1);
      });
      this.selectedFinancePayments = [];
    },
    createBankInstruction() {
      if (this.financePaymentItems == null || this.financePaymentItems.length <= 0) {
        this.sweetAlertError(this.$t("finance_payments.null_items"));
        return false;
      }
      let self = this;
      this.disabledSubmit = false;
      let cloneRequestPaymetItems = _.cloneDeep(self.financePaymentItems);
      self.tmpItems.forEach((formattedItem) => {
        let index = cloneRequestPaymetItems.findIndex(
          (payment) => payment.id == formattedItem.id
        );
        let amountToBePaid = 0;
        let currItem = formattedItem.total.items;
        currItem.forEach((item) => {
          amountToBePaid += Number(item.amount);
        });
        cloneRequestPaymetItems[index].amount_to_be_paid = Number(amountToBePaid);
        cloneRequestPaymetItems[index].currency_total = formattedItem.total.items;

        cloneRequestPaymetItems[index]["invoices"].forEach((item, subIndex) => {
          delete cloneRequestPaymetItems[index]["invoices"][subIndex]["invoice"];
        });
      });

      self.$store.commit(SET_LOADING, true);
      let payload = {
        url: `api/bank-instruction`,
        contents: {
          items: cloneRequestPaymetItems,
        },
      };

      this.$store.dispatch(CREATE_ITEM, payload).then((result) => {
        if (result.status) {
          this.disabledSubmit = true;
          self.sweetAlertSuccess(self.$t("general.successfully_saved")).then(() => {
            self.$router.push({ name: "bank_instructions.index" });
          });
          if (!(result.data && result.data.data && result.data.data.length)) {
            $state.complete();
          }
          if ($state) {
            $state.loaded();
          }
        } else {
          this.disabledSubmit = true;
          let response = result.data.response;
          this.sweetAlertError(response.data.message);
        }
        self.$store.commit(SET_LOADING, false);
      });
    },
    downloadExcelLink() {
      let item = this.financePayments;
      const downloadLink = document.createElement("a");
      downloadLink.href = item.excel_link;
      downloadLink.download = "bank_instruction.xlsx";
      downloadLink.click();
    },
    showFinancePaymentItem(id) {
      const newTab = window.open(`#/finance-payments/${id}/edit`, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        this.sweetAlertError(this.$t("bank_instruction.not_open_new_tab"));
      }
    },
    deleteFinancePayment(id) {
      let rowItem = this.financePaymentRows.find((row) => row.id === id);
      this.financePayments.push(rowItem);
      let paymentItemIndex = this.financePaymentItems.findIndex((row) => row.id === id);
      let tmpItemsIndex = this.tmpItems.findIndex((tmp) => tmp.id === id);
      this.tmpItems.splice(tmpItemsIndex, 1);
      this.financePaymentItems.splice(paymentItemIndex, 1);
    },
    deleteInvoice(id) {
      this.sweetAlertConfirm("general.are_you_sure").then((result) => {
        if (result) {
          let index = this.item.invoices.findIndex((invoice) => invoice.invoice.id == id);
          this.aprovableInvoices.push(this.item.invoices[index].invoice);
          this.item.invoices.splice(index, 1);
        }
      });
    },
    refreshItems() {
      let self = this;
      let url = "api/invoices/invoice-payment-request";

      let filters = {
        status_id: 47,
        all_items: 1,
      };
      let payload = {
        url: url,
        filters: filters,
      };
      this.$store.dispatch(GET_ITEMS, payload).then((result) => {
        if (result.status) {
          let data = result.data;
          data.forEach((item) => {
            let findItem = _.find(this.financePayments, function (f) {
              return f.id == item.id;
            });
            let findItemRows = _.find(this.financePaymentItems, function (f) {
              return f.id == item.id;
            });
            if (!findItem && !findItemRows) {
              this.financePayments.push(item);
            }
          });
        }
      });
    },
    getCurrencyUnits() {
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/currency-rate",
        })
        .then((results) => {
          if (results.status) {
            let responseItem = results.data.data;

            Object.keys(responseItem).forEach((key) => {
              responseItem[key]["is_active"] = false;
            });

            this.currencyUnits = responseItem;
          }
        });
    },
    getCurrencyUnits2() {
      let self = this;
      let apiurl = process.env.VUE_APP_BACKEND_APP_URL;
      axios
        .get("https://pulpopro.com/aletex-backend/public/api/currency-rate-2")
        .then((response) => {
          if (response.status) {
            let responseItem = response.data.data;

            Object.keys(responseItem).forEach((key) => {
              responseItem[key]["is_active"] = false;
            });

            this.currencyUnits = responseItem;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
  },
  mounted() {
    // this.getCurrencyUnits2();
    this.getCurrencyUnits();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.bank_instructions"), route: "bank_instruction.index" },
      { title: this.$t("bank_instruction.edit") },
    ]);

    if (!this.isUserGranted("Staff", ["financeManager"], false)) {
      return false;
    }
    if (this.id) {
      let url = `api/bank-instruction/${this.id}`;
      this.$store.dispatch(GET_ITEMS, { url: url }).then((result) => {
        if (result.status) {
          let data = result.data;
          this.financePayments = data;
        }
      });
    } else {
      let self = this;
      let url = "api/invoices/invoice-payment-request";

      let filters = {
        status_id: 47,
        all_items: 1,
      };
      let payload = {
        url: url,
        filters: filters,
      };
      this.$store.dispatch(GET_ITEMS, payload).then((result) => {
        if (result.status) {
          let data = result.data;
          self.financePayments = data;
          self.financePaymentRows = _.cloneDeep(data);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.h-50vh {
  height: 50vh !important;
}

.w-40 {
  width: 40% !important;
}
.invoice-title-font {
  font-weight: 400;
}
</style>
