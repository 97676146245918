<template>
  <div :class="{'form-inline d-flex': isInline, 'mb-3': hasBottomMargin}">
    <label
        :for="name"
        class="d-inline-block col-form-label font-size-lg font-size-lg mr-2"
        style="width: auto !important;"
        :class="labelClasses"
    >
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
      <info-tooltip
          :helper-text="helperText"
          v-if="helperText"
      ></info-tooltip>
    </label>
    <div class="form-group mb-0"
         :class="{
            'd-block': isInline,
            'd-inline': !isInline
          }">
      <input
          :class="{
                    'form-control-sm': size.toString() === 'sm',
                    'form-control-lg': size.toString() === 'lg',
                    'is-valid': Number(modelProxy) !== 0,
                    'mr-0': true,

                }"
          :disabled="disabled"
          :id="name"
          :name="name"
          type="number"
          class="form-control"
          :placeholder="placeholder"
          :required="required"
          v-model="modelProxy"
          :min="min"
          :max="max"
          :step="step"
      >
      <span v-if="hint"
            class="form-text text-muted offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12">{{
          hint
        }}</span>
      <p v-if="error" class="text-danger pt-1 px-3 block offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12">
        {{ $t(error) }}</p>
    </div>
    <span class="d-inline-block px-1" v-html="getIconByKey(iconName, {
            class: 'w-20px h-20px object-cover d-inline-block opacity-75',
      })">
    </span>
    <select
        :class="{
                  'form-control-sm': size.toString() === 'sm',
                  'form-control-lg': size.toString() === 'lg',
                  'is-valid': Number(unitModelProxy) !== 0 && unitModelProxy !== 'null',
                }"
        class="form-control"
        :id="name"
        v-model="unitModelProxy"
        :required="required"
        :disabled="disabled || disabledUnit">
      <option :value="index" v-for="(value, index) in unitOptions">{{ $t(value) }}</option>
    </select>
  </div>
</template>

<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "NumberInputWithUnit",
  props: {
    name: {
      required: false,
      default: 'number-input-with-unit'
    },
    model: {},
    title: {},
    disabled: {
      required: false,
      default: false,
    },
    isInline: {
      required: false,
      default: true,
    },
    error: {},
    hint: {},
    placeholder: {},
    required: {
      default: true
    },
    hasBottomMargin: {
      required: false,
      default: true,
    },
    min: {
      required: false,
      default: -Infinity,
    },
    max: {
      required: false,
      default: Infinity
    },
    step: {
      required: false,
      default: '0.01'
    },
    isValid: {
      required: false,
      default: null,
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
    unitModel: {
      required: true,
    },
    unitOptions: {
      required: true,
    },
    unitIsValid: {
      required: false,
      default: null,
    },
    iconName: {
      required: false,
      default: 'icons.waybill.measure'
    },
    disabledUnit: {
      required: false,
      default: false,
    }

  },
  components: {
    InfoTooltip,
  },
  computed: {
    labelClasses() {
      let result = {
        'col-form-label-sm': this.size.toString() === 'sm',
        'col-form-label-lg': this.size.toString() === 'lg',
        'd-inline-block': this.isInline,
        'd-block': !this.isInline,
      };
      if (Object(this.labelClass) === this.labelClass) {
        result = {...result, ...this.labelClass};
      } else if (Array.isArray(this.labelClass)) {
        this.labelClass.forEach(value => {
          result[value] = true
        })
      } else if (this.labelClass !== null) {
        result[this.labelClass] = true;
      }
      return result;
    },
    modelProxy: {
      get() {
        return this.model
      },
      set(value) {
        if (Number(value) > Number(this.max)) {
          this.error = 'general.please_enter_valid_amount'
          this.$emit('update:model', this.max + '')
          this.$emit('update', {name: this.name, value: this.max});
        } else if (Number(value) < Number(this.min)) {
          this.error = 'general.please_enter_valid_amount'
          this.$emit('update:model', this.min + '')
          this.$emit('update', {name: this.name, value: this.min});
        } else {
          this.$emit('update:model', value + '')
          this.$emit('update', {name: this.name, value: value});
        }
      }
    },
    unitModelProxy: {
      get() {
        return this.unitModel;
      },
      set(value) {
        this.$emit('update:unitModel', value);
      },
    }
  },
}
</script>


