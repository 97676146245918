<template>
  <div class="p-0 m-0">
    <div :class="{ 'form-inline d-flex': isInline, 'mb-3': hasBottomMargin }">
      <label :class="labelClasses" :for="name" style="width: auto !important">
        {{ title }}
        <span v-if="required" class="text-danger">*</span>
        <info-tooltip v-if="helperText" :helper-text="helperText"></info-tooltip>
      </label>
      <div :class="formGroupClasses">
        <input
          :id="name"
          v-model="displayAmount"
          :class="inputClasses"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"
          :required="required"
          :style="{
            maxWidth: inputMaxWidth + ' !important',
            width: inputWidth + ' !important',
          }"
          :type="type"
          :maxlength="maxlength"
          class="form-control"
        />
        <span
          :class="{
            'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': isInline,
          }"
          class="form-text text-muted"
        >
          {{ hint }}
        </span>
        <p
          v-if="error"
          :class="{ 'col-lg-12 col-md-12 col-sm-12': isInline }"
          class="text-danger pt-1 px-3 block"
        >
          {{ $t(error) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

export default {
  name: "AmountInputs",
  data() {
    return {
      displayAmount: Number(this.model).toLocaleString("tr-TR"),
    };
  },
  props: {
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    name: {},
    model: {},
    title: {},
    disabled: {
      required: false,
      default: false,
    },
    error: {},
    hint: {},
    placeholder: {},
    required: {
      default: true,
    },
    isValid: {
      required: false,
      default: null,
    },
    disabledErrorPopUp: {
      required: false,
      default: true,
    },
    type: {
      required: false,
      default: "text",
    },
    size: {
      default: "sm",
      validator: function validator(l) {
        return ["xs", "sm", "md", "lg"].indexOf(l) > -1;
      },
    },
    hasBottomMargin: {
      required: false,
      default: true,
    },
    isInline: {
      required: false,
      default: true,
    },
    inputMaxWidth: {
      required: false,
      default: "150px",
    },
    inputWidth: {
      required: false,
      default: "w-auto",
    },
    formGroupClass: {
      required: false,
      default: null,
    },
    labelClass: {
      required: false,
      default: null,
    },
    inputClass: {
      required: false,
      default: null,
    },
    maxlength: {
      required: false,
      default: null,
    },
  },
  components: {
    InfoTooltip,
  },
  computed: {
    labelClasses() {
      let result = {
        "col-form-label-sm": this.size.toString() === "sm",
        "col-form-label-lg": this.size.toString() === "lg",
        "d-inline-block": this.isInline,
        "d-inline": !this.isInline,
      };
      if (Object(this.labelClass) === this.labelClass) {
        result = { ...result, ...this.labelClass };
      } else if (Array.isArray(this.labelClass)) {
        this.labelClass.forEach((value) => {
          result[value] = true;
        });
      } else if (this.labelClass !== null) {
        result[this.labelClass] = true;
      }
      return result;
    },
    inputClasses() {
      let result = {
        "form-control-sm": this.size.toString() === "sm",
        "form-control-lg": this.size.toString() === "lg",
        "is-valid":
          this.isValid == null
            ? false
            : (this.isValid && this.displayAmount !== null) || this.displayAmount === "",
        "is-invalid": this.isValid == null ? false : !this.isValid,
      };
      if (Object(this.inputClass) === this.inputClass) {
        result = { ...result, ...this.inputClass };
      } else if (Array.isArray(this.inputClass)) {
        this.inputClass.forEach((value) => {
          result[value] = true;
        });
      } else if (this.inputClass !== null) {
        result[this.inputClass] = true;
      }
      return result;
    },
    formGroupClasses() {
      let result = {
        "mx-3": this.isInline,
        "d-inline-block": this.isInline,
        "d-inline": !this.isInline,
      };

      if (Object(this.formGroupClass) === this.formGroupClass) {
        result = { ...result, ...this.formGroupClass };
      } else if (Array.isArray(this.formGroupClass)) {
        this.formGroupClass.forEach((value) => {
          result[value] = true;
        });
      } else if (this.formGroupClass !== null) {
        result[this.formGroupClass] = true;
      }
      return result;
    },
  },
  watch: {
    // model(){
    //   this.displayAmount = Number(this.model).toLocaleString("tr-TR");
    // },
    displayAmount(value, oldValue) {
      let formattedValue = value.replace(/[^0-9,]/g, "").replace(/\./g, ""); // Sayısal olmayan karakterleri kaldırıyoruz, Noktaları siliyoruz

      if (!formattedValue.endsWith(",") && !formattedValue.endsWith(",0")) {
        formattedValue = formattedValue.replace(",", ".");
        this.model=formattedValue;
        this.$emit("update:model", formattedValue);
        this.displayAmount = Number(formattedValue).toLocaleString("tr-TR");
      }
    },
  },
};
</script>
